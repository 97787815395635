import React from "react";
import Github from "../../assets/github.png";
import Instagram from "../../assets/instagram.png";
import Linkedin from "../../assets/linkedin.png";
import Logo from "../../assets/logo.png";
import "./footer.css";

function footer() {
  return (
    <>
      <div className="Footer-container">
        <hr />
        <div className="footer">
          <div className="social-links">
            <img src={Github} alt="GitHub" />
            <img src={Instagram} alt="Instagram" />
            <img src={Linkedin} alt="LinkedIn" />
          </div>
          <div className="logo-f">
            <img src={Logo} alt="Logo" />
          </div>
        </div>

        <div className="blur blur-f-1"></div>
        <div className="blur blur-f-2"></div>
      </div>
    </>
  );
}

export default footer;
