import React from "react";
import { programsData } from "../../data/programsData.js";
import RightArrow from "../../assets/rightArrow.png";
import "./programs.css";

function Programs() {
  return (
    <>
      <div className="Programs" id="programs">
        {/* header */}
        <div className="programs-header">
          <span className="stroke-text">Explore our </span>
          <span>Programs</span>
          <span className="stroke-text">to Shape you</span>
        </div>
      </div>
      {/* program category */}
      <div className="program-category">
        {programsData.map((program, index) => (
          <div className="category" key={index}>
            {program.image}
            <span>{program.heading}</span>
            <span>{program.details}</span>
            <div className="join-now">
              <span>Join Now </span>
              <img src={RightArrow} alt="" />
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default Programs;
