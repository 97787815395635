import "./hero.css";
import Header from "../Header/header.jsx";
import hero_image from "../../assets/hero_image.png";
import hero_image_back from "../../assets/hero_image_back.png";
import Heart from "../../assets/heart.png";
import Calories from "../../assets/calories.png";
import { motion } from "framer-motion";
import NumberCounter from "number-counter";

function hero() {
  const transition = { type: "spring", duration: 3 };
  const mobile = window.innerWidth <= 768 ? true : false;

  return (
    <>
      <div className="hero" id="home">
        <div className="blur hero-blur"></div>
        <div className="left-h">
          <Header />
          {/* The best ad banner */}
          <div className="the-best-ad">
            <motion.div
              initial={{ left: mobile ? "178px" : "238px" }}
              whileInView={{ left: "8px" }}
              transition={transition}
              className="moving-div"
            ></motion.div>
            <span>THE BEST FITNESS CLUB IN THE TOWN </span>
          </div>
          {/* Hero heading */}
          <div className="hero-text">
            <div>
              <span className="stroke-text">SHAPE </span>
              <span>YOUR </span>
            </div>
            <div>
              <span>IDEAL </span>
              <span>BODY </span>
            </div>
            <div>
              <span>
                In here we will help you to shape and build your body and level
                up your strength.
              </span>
            </div>
          </div>

          {/* figures */}
          <div className="figures">
            <div>
              <span><NumberCounter end = {140} start = {100} delay = '4' prefix = "+"/>
              </span>
              <span>Expert Coaches</span>
            </div>
            <div>
              <span><NumberCounter end = {970} start = {700} delay = '10' prefix = "+"/></span>
              <span>Member Joined</span>
            </div>
            <div>
              <span><NumberCounter end = {48} start = {10} delay = '4' prefix = "+"/></span>
              <span>Fitness Courses</span>
            </div>
          </div>

          {/* hero Buttons */}
          <div className="hero-button">
            <button className="btn">Get Started</button>
            <button className="btn">Learn More</button>
          </div>
        </div>
        <div className="right-h">
          <button className="btn">Join Now</button>

          <motion.div
            transition={transition}
            className="heart-rate"
            initial={{ right: "-1rem" }}
            whileInView={{ right: "4rem" }}
          >
            <img src={Heart} alt="" />
            <span>Heart Rate</span>
            <span>116 bpm</span>
          </motion.div>
        </div>

        {/* hero-imagess */}
        <img src={hero_image} alt="" className="hero_image" />
        <motion.img
          initial={{ right: "11rem" }}
          whileInView={{ right: "20rem" }}
          transition={transition}
          src={hero_image_back}
          alt=""
          className="hero_image_back"
        />

        {/* Calories  */}
        <motion.div
          className="calories"
          initial={{ right: "37rem" }}
          whileInView={{ right: "28rem" }}
        >
          <img src={Calories} alt="" />
          <div>
            <span>Calories Burned</span>
            <span>220 kcal</span>
          </div>
        </motion.div>
      </div>
    </>
  );
}

export default hero;
