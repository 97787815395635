import React, { useRef } from "react";
import Emailjs from "@emailjs/browser";
import "./join.css";

function Join() {  
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    Emailjs
      .sendForm("service_lhhp5vl", "template_4kmicgs", form.current, {
        publicKey: "EkUP0C3OHI3SybzmQ",
      })
      .then(
        () => {
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <>
      <div className="Join" id="join">
        <div className="left-j">
          <hr />
          <div>
            <span className="stroke-text"> READY TO </span>
            <span> LEVEL UP </span>
          </div>
          <div>
            <span> YOUR BODY </span>
            <span className="stroke-text">WITH US ?</span>
          </div>
        </div>
        <div className="right-j">
          <form ref={form} className="email-container" onSubmit={sendEmail}>
            <input
              type="email"
              name="user-email"
              placeholder="Enter your Email Address"
            />
            <button type="submit" className="btn btn-j">Join Now</button>
          </form>
        </div>
      </div>
    </>
  );
}

export default Join; 
